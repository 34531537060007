/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import Following from '../../components/Following';
import TagsList from '../../components/TagsList';
import Posts from '../../components/Posts';
import userService from '../../services/userService';
import { getCookie } from '../../helpers/common';
import SuggestedFollowers from '../../components/SuggestedFollowers';
import UserContext from '../../context/userContext';
import Loading from '../../components/Loading';

function HomeFeed() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(null);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 10000,
  });
  const { categories_data } = userService.GetCategories();
  const { followedProfiles } = useContext(UserContext);
  const [followersSearchQuery, setFollowersSearchQuery] = useState({
    page: 1,
    itemsPerPage: 10000,
    profileId: getCookie('current_profile'),
  });
  const {
    followers_data: { items, hasMore },
  } = userService.GetFollowersByProfile(followersSearchQuery);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const profileId = getCookie('current_profile');
        const res = await userService.getPosts(profileId, null, category, searchQuery.page, searchQuery.pageSize);
        if (res?.posts?.length) {
          setPosts(res?.posts);
          setLoading(false);
        } else {
          setLoading(false);
          setPosts([]);
        }
      } catch (err) {
        console.log({ err });
        setLoading(false);
      }
    })();
  }, [category, searchQuery]);

  const handleTagChange = ({ name, value }) => {
    setCategory(value);
  };
  const handlePostAction = (postId, action, index) => {
    const arr = [...posts];
    const selected_post = posts?.filter(itm => itm.id === postId)[0];

    if (action === 'save') {
      selected_post.saved = true;
    } else if (action === 'unsave') {
      selected_post.saved = false;
    } else if (action === 'liked') {
      selected_post.liked_myself = true;
      selected_post.likes += 1;
    } else {
      selected_post.liked_myself = false;
      selected_post.likes -= 1;
    }
    arr[index] = selected_post;
    setPosts(arr);
  };
  return (
    <section className="container">
      {!!followedProfiles?.length && <Following followers={followedProfiles} />}
      <TagsList
        categories={categories_data}
        onChange={target => {
          handleTagChange(target);
        }}
      />

      <div className="post-suggestion-content feed-section">
        <div className="posts-holder">
          {loading ? (
            <Loading />
          ) : posts?.length ? (
            <div className="feed-section">
              <Posts posts={posts} handlePostAction={handlePostAction} />
            </div>
          ) : (
            <div className="no-records">
              <div className="text-danger mx-auto no-record">
                <span>No Posts Found</span>
              </div>
            </div>
          )}
        </div>
        <div className="suggestions">
          <SuggestedFollowers />
        </div>
      </div>
    </section>
  );
}

export default HomeFeed;
