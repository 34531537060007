import React from 'react';
import './Posts.scss';

import PostCard from './PostCard';

function Posts({ posts: items, loading, handlePostAction = () => {} }) {
  return loading ? (
    <p>Loading...</p>
  ) : items?.length ? (
    <div className="posts">
      {items?.map((itm, index) => (
        <PostCard item={itm} key={index} handlePostAction={handlePostAction} />
      ))}
    </div>
  ) : (
    <div className="no-records">
      <div className="text-danger mx-auto no-record">
        <span>No Posts Found</span>
      </div>
    </div>
  );
}

export default Posts;
