import React, { useState } from 'react';
import Tag from './Tag';

function TagsList({ categories, onChange }) {
  const [isActive, setIsActive] = useState(1);
  return (
    <div className="tags-list">
      {categories?.map(itm => (
        <Tag item={itm} isActive={isActive} setIsActive={setIsActive} onChange={onChange} />
      ))}
    </div>
  );
}

export default TagsList;
