/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import './Field.scss';
// import DatePicker from 'react-datepicker';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect } from 'react';
import Select from '../Select';

function Field({ label, error, value, options, name, variant, type, onChange = () => {}, ...rest }) {
  const [startDate, setStartDate] = useState('');
  useEffect(() => {
    if (value) {
      setStartDate(new Date(value));
    }
  }, []);
  return (
    <div className="field-container">
      {type === 'datePicker' ? (
        <DatePicker
          value={startDate}
          className="datepicker"
          // placeholder={placeholder}
          dayPlaceholder="dd"
          yearPlaceholder="yyyy (Date of Birth)"
          monthPlaceholder="mm"
          popperPlacement="bottom"
          calendarClassName="calendar-container"
          // portalContainer={({ children }) => <div className="calendar-container">{children}</div>}
          onChange={date => {
            console.log({ date });
            const fixDate = dateParram =>
              // return new Date(date.getTime() - userTimezoneOffset).toJSON();
              new Date(dateParram).toJSON();
            // onChange({ target: { value: e ? fixDate(e) : '', name: props.name } });
            setStartDate(date);
            onChange({ name, value: fixDate(date) });
          }}
          format="MM/dd/y"
        />
      ) : type === 'textarea' ? (
        <>
          <label className="field-label">{label}</label>
          <textarea
            className={`field-input ${error ? 'field-error' : ''} ${variant === 'form' ? 'field-form' : ''}`}
            onChange={e => {
              onChange({ name, value: e.target.value });
            }}
            value={value}
            {...rest}
          />
        </>
      ) : type === 'select' ? (
        <Select
          options={options}
          name={name}
          onChange={({ name, value }) => {
            onChange({ name, value });
          }}
          value={value}
          {...rest}
        />
      ) : (
        <>
          <label className="field-label">{label}</label>
          <input
            className={`field-input ${error ? 'field-error' : ''} ${variant === 'form' ? 'field-form' : ''}`}
            {...rest}
            type={type}
            onChange={e => {
              onChange({ name, value: e.target.value });
            }}
            value={value}
          />
        </>
      )}
      {error && <div className="field-error-message">{error}</div>}
    </div>
  );
}

export default Field;
