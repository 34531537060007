/* eslint-disable no-unused-vars */
import React from 'react';
import { navigate } from 'gatsby';
import Avatar from '../Avatar';
import './Following.scss';

function Following({ followers }) {
  const handleProfileClick = profileId => {
    navigate(`/user/profile?id=${profileId}`);
  };
  return (
    <div className="following">
      <div className="text-holder">
        <p>Following</p>
      </div>
      <div className="avatar-holder">
        {followers.map(itm => (
          <Avatar
            url={itm?.profile_photo}
            onClick={() => {
              handleProfileClick(itm?._id);
            }}
          />
        ))}
        {/* <div className="text-center">No Followers...</div> */}
      </div>
    </div>
  );
}

export default Following;
