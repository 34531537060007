/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import './Tags.scss';

function Tag({ item, isActive, setIsActive, onChange = () => {}, ...rest }) {
  return (
    <div
      className={`tag ${isActive === item.value ? 'selected' : ''}`}
      {...rest}
      onClick={() => {
        if (isActive === item?.value) {
          setIsActive(1);
          onChange({ name: null, value: null });
          return;
        }
        setIsActive(item?.value);
        onChange({ name: item?.label, value: item?.value });
      }}>
      {item?.label}
    </div>
  );
}

export default Tag;
