/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import axios from 'axios';
import { navigate } from 'gatsby';
import CreatePostForm from '../../components/CreatePostForm';
import UploadMedia from '../../components/UploadMedia';
import { getCookie } from '../../helpers/common';
import userService from '../../services/userService';
import Toast from '../../components/Toast';

function CreatePost() {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    caption: '',
    details: '',
    category: '',
    media: '',
  });
  const [values, setValues] = useState({
    caption: '',
    details: '',
    category: '',
    profile_id: getCookie('current_profile'),
    media: '',
  });
  const [assets, setAssets] = useState();

  const handleSubmit = async () => {
    const hasErrors =
      Object.values(errors)?.some(error => error !== '') || !Object.values(values)?.every(value => value !== '');
    if (hasErrors) {
      Toast({
        type: 'error',
        message: 'Please fill all the required fields',
      });
      return;
    }
    const payload = {
      ...values,
      category: values?.category?.value,
    };
    setLoading(true);
    let resp = {};
    try {
      const res = await userService.createPost(payload);
      resp = res;
      if (res?.write_url) {
        const response = await axios({
          method: 'put',
          url: res?.write_url, // write URL
          data: assets,
          headers: {
            'Content-Type': assets.type,
          },
        });
      }
      if (res?.success) {
        setLoading(false);
        navigate('/user/feed');
      } else {
        navigate('/user/feed');
      }
    } catch (err) {
      setLoading(false);
      Toast({ type: 'error', message: 'Something went wrong' });
      // await userService.createPost({ is_delete: true, id: resp?.post?.id, profile_id: resp?.post?.profile_id });
    }
  };
  const handleMediaChange = ({ name, value }) => {
    setAssets(value);
    const file_obj = { file_name: value?.name, file_type: value?.type };
    setValues(prev => ({ ...prev, media: file_obj }));
  };

  return (
    <>
      <UploadMedia onChange={handleMediaChange} name="media" />
      <CreatePostForm
        values={values}
        setValues={setValues}
        errors={errors}
        setErrors={setErrors}
        loading={loading}
        onSubmit={handleSubmit}
      />
    </>
  );
}

export default CreatePost;
