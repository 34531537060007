import React from 'react';
import Posts from '../../components/Posts';

function GuestBook({ user }) {
  return (
    <>
      <div className="guest-text">
        <p>{user?.about_me}</p>
      </div>
      <Posts />
    </>
  );
}

export default GuestBook;
