/* eslint-disable no-shadow */
import React, { useState } from 'react';
import './Select.scss';

function Select({ options, name, onChange = () => {}, value, placeholder, ...rest }) {
  const [selectedValue, setSelectedValue] = useState('');
  const handleChange = val => {
    const filtered = options?.filter(itm => String(itm?.value) === val)[0];
    setSelectedValue(filtered);
    onChange({ name, value: filtered });
  };
  return (
    <div className="select-container">
      <select
        className="select"
        onChange={e => {
          const {
            target: { value },
          } = e;
          const val = value?.toString();
          handleChange(val);
        }}
        value={value?.value}
        {...rest}>
        <option value="" disabled selected className="d-none option">
          {placeholder}
        </option>

        {options?.map(option => (
          <option className="option" key={option.value} value={option?.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="dropdown-icon">
        <svg viewBox="0 0 24 24">
          <path d="M7 10l5 5 5-5z" />
        </svg>
      </div>
    </div>
  );
}

export default Select;
