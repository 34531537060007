import React, { useContext, useState } from 'react';
import { navigate } from '@gatsbyjs/reach-router';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Field from '../../components/Field';
import laptop from '../../images/laptop.png';
import logo from '../../images/logo_ilegacy.png';
import warIcon from '../../images/war_ic.svg';
import './Otp.scss';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import userService from '../../services/userService';
import { setCookie } from '../../helpers/common';
import UserContext from '../../context/userContext';
import Toast from '../../components/Toast';

function Otp() {
  const [showErrModal, setShowErrModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(null);
  const { setUser } = useContext(UserContext);
  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);

    userService
      .verifyCode(code)
      .then(res => {
        console.log('otp', { res });
        // if (res?.status !== 200) {
        //   throw Error('Invalid Code!');
        // }
        Toast({ type: 'success', message: res?.message ?? 'Code verified successfully' });
        setCookie('itombs_token', res?.token);
        if (res?.profiles?.length && res?.qr_remaining_count > 0) {
          navigate('/user/link-profile-qr');
        } else if (res?.profiles?.length && !res?.qr_remaining_count) {
          navigate('/user/choose-profile');
        } else if (res?.token && !res?.profiles?.length) {
          setLoading(false);
          navigate('/user/create-profile');
        } else {
          setShowErrModal(true);
        }
      })
      .catch(() => {
        setShowErrModal(true);
        setLoading(false);
        setShowErrModal(true);
      });
  };
  return (
    <>
      <div className="otp-component">
        <div className="logo-holder">
          <img src={logo} alt="logo" />
        </div>
        <div className="otp-img">
          <div className="img-holder">
            <img src={laptop} alt="laptop" />
          </div>
        </div>
        <div className="form-holder">
          <div className="text-holder">
            <h2>Confirm code</h2>
            <p>Three digit code sent on your email address</p>
          </div>
          <div>
            <form className="form" onSubmit={handleSubmit}>
              <Field
                label=""
                placeholder="X-X-X"
                name="otp"
                value={code}
                onChange={({ value }) => setCode(value)}
                // error={error && error.includes('Name')}
              />
              <div className="btn-holder">
                <Button variant="primary" type="submit" loading={loading}>
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal isOpen={showErrModal} setIsOpen={setShowErrModal} isClosable sm error>
        <div className="modal-error">
          <div className="title-holder">
            <h3>Wrong Code</h3>
            <button
              className="close-modal-btn"
              type="button"
              onClick={() => {
                setShowErrModal(!showErrModal);
                document.body.style.overflow = 'visible';
              }}>
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>
          <div className="content">
            <div className="img-holder">
              <img src={warIcon} alt="war_icon" />
            </div>
            <div className="text-holder">
              <p>
                The confirmation code does not match,
                <br /> Please try again.
              </p>
            </div>
            <div className="btn-holder">
              <Button
                variant="primary"
                onClick={() => {
                  setShowErrModal(false);
                }}>
                Ok
              </Button>
            </div>
          </div>
        </div>
        {/* <div className="container">
          <div className="textHolder">
            <h4>You have entered an invalid code. Please enter correct code.</h4>
          </div>
          <div className="btnHolder">
            <Button
              sm
              type="primary"
              onClick={() => {
                // setShowErrModal(prev => !prev);
                document.body.style.overflow = 'visible';
              }}>
              OK
            </Button>
          </div>
        </div> */}
      </Modal>
    </>
  );
}

export default Otp;
