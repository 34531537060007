/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import './Modal.scss';
// import ReactDOM from 'react-dom';
// const modalRoot = typeof document !== `undefined` ? document.getElementById('modal-root') : null;

function Modal({
  isOpen,
  error,
  setIsOpen = () => {},
  title,
  children,
  sm,
  md,
  lg,
  xl,
  isClosable = true,
  imgPreview,
  width,
  helpModal,
}) {
  const modalRef = React.useRef();

  const closeModal = e => {
    if (modalRef.current === e.target && isClosable) {
      setIsOpen(false);
      document.body.style.overflow = 'visible';
    }
  };

  const keyPress = React.useCallback(
    e => {
      if (e.key === 'Escape' && isOpen && isClosable) {
        setIsOpen(false);
        document.body.style.overflow = 'visible';
      }
    },
    [setIsOpen, isOpen],
  );

  React.useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [isOpen]);

  return (
    isOpen && (
      <div className="background" onClick={closeModal} ref={modalRef}>
        <div
          className={`modal-wrapper ${isOpen && 'modal-open'} ${xl && 'xl'} ${md && 'md'} ${lg && 'lg'} ${sm && 'sm'} ${
            width && 'custom-width'
          } ${imgPreview && 'imgPreview'}`}>
          <div
            className="modal-content"
            style={{
              background: helpModal ? 'var(--dark)' : 'var(--white)',
            }}>
            {children}
          </div>
        </div>
      </div>
    )
  );
}

export default Modal;
