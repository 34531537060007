/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceSmile } from '@fortawesome/free-regular-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Modal from '../Modal';
import Avatar from '../Avatar';
import './CommentModal.scss';
import Button from '../Button';
import userService from '../../services/userService';
import Loading from '../Loading';
import Comments from '../Comment/Comments';
import { getCookie } from '../../helpers/common';
import Toast from '../Toast';
import UserContext from '../../context/userContext';

function CommentModal({ isOpen = true, setIsOpen = () => {}, post, setTotalComments }) {
  const [showPicker, setShowPicker] = useState(false);
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);
  const [commentLoading, setCommentLoading] = useState(false);
  const { hasPermission } = useContext(UserContext);

  const emojiRef = useRef(null);
  const { comments_data, comments_loading } = userService.GetComments(post?.id);
  const handleEmojiSelect = emoji => {
    // setShowPicker(false);
    setComment(comment.concat(emoji.native));
    // onEmojiSelect(emoji.native);
  };

  const handleInput = e => {
    const { value } = e.target;
    setComment(value);
  };
  const handleAddComment = async postId => {
    setCommentLoading(true);
    try {
      const payload = {
        message: comment,
        reply_to: null,
        profile_id: getCookie('current_profile'),
        post_id: postId,
      };
      const res = await userService.addComment(payload);
      setComment('');
      setCommentLoading(false);
      setTotalComments(prev => prev + 1);
      setComments(prev => [...prev, res?.comment]);
    } catch (error) {
      Toast({ type: 'error', message: error?.message });
      setCommentLoading(false);
    }
  };
  useEffect(() => {
    if (comments_data?.length) {
      setComments(comments_data);
    }
  }, [comments_data]);
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} isClosable md>
      {comments_loading ? (
        <Loading />
      ) : (
        <div className="comment-content">
          {/* <div className="img-holder">
          <img src={post?.img_url ?? girls} alt="people" />
        </div> */}
          <div className="info-holder">
            <div className="title-holder">
              <div className="title">
                <Avatar sm url={post?.profile_url} />
                <h5>{post?.profile_name}</h5>
              </div>
              <div
                className="icon"
                onClick={() => {
                  setIsOpen(false);
                }}>
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
            <Comments
              comments={comments}
              setComments={setComments}
              // setCommentsLoading={setCommentsLoading}
              setTotalComments={setTotalComments}
              commentsLoading={comments_loading}
            />
            {/* <div className="social-icons">
              <div className="icon">
                <FontAwesomeIcon icon={faHeart} />
              </div>
              <div className="icon">
                <FontAwesomeIcon icon={faPaperPlane} />
              </div>
            </div>
            <div className="followers">
              <span>{post?.followers ?? 0} Likes</span>
            </div> */}
            <div className="add-comment">
              <div className="input-holder">
                <span className="smiley">
                  <div
                    ref={emojiRef}
                    className="ico-smile"
                    onClick={() => {
                      setShowPicker(!showPicker);
                    }}>
                    <FontAwesomeIcon icon={faFaceSmile} />
                  </div>

                  {showPicker && (
                    <Picker
                      data={data}
                      onEmojiSelect={handleEmojiSelect}
                      onClickOutside={e => {
                        if (emojiRef.current && !emojiRef.current.contains(e.target)) {
                          setShowPicker(false);
                        }
                      }}
                    />
                  )}
                </span>
                <input type="text" value={comment} placeholder="Add comment.." onChange={handleInput} />
                <Button
                  variant="primary"
                  loading={commentLoading}
                  disabled={commentLoading || !comment}
                  onClick={() => {
                    hasPermission(() => handleAddComment(post.id));
                  }}>
                  Post
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default CommentModal;
