import React from 'react';
import Comment from '.';
import './Comment.scss';

function Comments({ comments, setComments, setTotalComments }) {
  return (
    <ul className="comments-holder">
      {comments?.length ? (
        comments.map((comment, key) => (
          <Comment
            comment={comment}
            comments={comments}
            setComments={setComments}
            key={key}
            setTotalComments={setTotalComments}
          />
        ))
      ) : (
        <span>No comments found</span>
      )}
    </ul>
  );
}

export default Comments;
