import React from 'react';
import TagsList from '../../components/TagsList';
import Posts from '../../components/Posts';

function PostView({ posts, postsLoading }) {
  return (
    <>
      <TagsList />
      <Posts posts={posts} loading={postsLoading} />
    </>
  );
}

export default PostView;
