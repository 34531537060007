import React from 'react';
import Avatar from '../Avatar';
import './Comment.scss';
import { formatDistanceToNow, fromUnixTime } from 'date-fns';
import { formatTimeAgo } from '../../helpers/common';

function Comment({ comment, setComments, comments, key }) {
  return (
    <li className="comments-list">
      <div className="comment">
        <div className="avatar-holder">
          <Avatar sm url={comment?.media?.profile_photo} />
        </div>
        <div className="text">
          <p>{comment?.message}</p>
          <div className="timeline">
            <span>{formatTimeAgo(comment.timestamp)}</span>
            <span>{comment?.likes ?? 0} Likes</span>
            <span>Reply</span>
          </div>
        </div>
      </div>
    </li>
  );
}

export default Comment;
