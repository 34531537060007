import React, { useState } from 'react';
import Field from '../Field';
import './EditProfileForm.scss';
import Button from '../Button';

function EditProfileForm({
  create,
  state,
  values,
  setValues,
  errors,
  setErrors,
  btnLoading,
  onSubmit = () => {},
  ...rest
}) {
  const handleSubmit = e => {
    e.preventDefault();

    onSubmit();
  };
  const validateField = (name, value) => {
    // Implement field-specific validation logic here
    let error = '';

    if (name === 'name' && !value) {
      error = 'Name is required';
    }

    // if (name === 'date' && !value) {
    //   error = 'Date of Birth is required';
    // }

    // if (name === 'phone_number' && !value) {
    //   error = 'Phone Number is required';
    // }
    // if (name === 'address' && !value) {
    //   error = 'Address is required';
    // }
    // if (name === 'about_me' && !value) {
    //   error = 'About me is required';
    // }

    // Add more field-specific validation rules as needed

    return error;
  };
  const handleChange = ({ name, value }) => {
    setValues(prev => ({ ...prev, [name]: value }));
    // Perform validation on individual field change
    const validationError = validateField(name, value);
    console.log({ validationError });
    setErrors(prev => ({ ...prev, [name]: validationError })); // Set the error for the corresponding field
  };
  return (
    <div className="edit-form">
      <form onSubmit={handleSubmit}>
        <Field
          type="text"
          variant="form"
          name="name"
          value={values.name}
          label=""
          error={errors.name}
          placeholder="Name"
          onChange={({ name, value }) => {
            handleChange({ name, value });
          }}
        />
        <Field
          type="datePicker"
          variant="form"
          value={values.date}
          error={errors?.date}
          placeholder="Date of Birth"
          name="date"
          label=""
          onChange={({ name, value }) => {
            handleChange({ name, value });
          }}
        />
        <Field
          type="number"
          variant="form"
          value={values.phone_number}
          name="phone_number"
          label=""
          error={errors?.phone_number}
          placeholder="Phone Number"
          onChange={({ name, value }) => {
            handleChange({ name, value });
          }}
        />
        <Field
          type="text"
          variant="form"
          value={values.address}
          name="address"
          label=""
          error={errors?.address}
          placeholder="Address"
          onChange={({ name, value }) => {
            handleChange({ name, value });
          }}
        />
        <Field
          type="textarea"
          variant="form"
          value={values.about_me}
          name="about_me"
          rows="4"
          label=""
          error={errors?.about_me}
          placeholder="About me.."
          onChange={({ name, value }) => {
            handleChange({ name, value });
          }}
        />
        <div className="btn-holder">
          <Button htmlType="submit" type="submit" variant="primary" loading={btnLoading}>
            {create ? 'Save' : 'Update'}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default EditProfileForm;
