/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import Avatar from '../Avatar';
import laptop from '../../images/login.png';
import './LinkProfileToQR.scss';
import logo from '../../images/logo_ilegacy.png';
import Button from '../Button';
import UserContext from '../../context/userContext';
import { decodeToken, getCookie, setCookie } from '../../helpers/common';
import userService from '../../services/userService';
import Loading from '../Loading';

function LinkProfileToQR() {
  const { setUser } = useContext(UserContext);
  const [profile, setProfile] = useState({});
  const queryParams = new URLSearchParams(window.location.search);
  const qr = queryParams.get('qr');
  const { profiles_data: profiles, profiles_loading } = userService.GetUserProfiles();

  // useEffect(() => {
  //   const itombs_token = getCookie('itombs_token');
  //   if (itombs_token) {
  //     const decodedToken = decodeToken(itombs_token);
  //     const profileId = getCookie('current_profile');
  //     console.log({ decodedToken, profileId });
  //     if (decodedToken?.email && profileId) {
  //       // show link profile
  //       // get profile from localstorage
  //       const existingProfile = localStorage.getItem('itombs_profile');
  //       console.log({ existingProfile });
  //       if (existingProfile) {
  //         setProfile({ ...JSON.parse(existingProfile), qr });
  //       } else {
  //         navigate('/user/login');
  //       }
  //     }
  //   }
  // }, []);
  console.log({ profile, qr });
  const handleClickProfile = item => {
    userService
      .linkProfilewithQR({ profileId: item._id, qr })
      .then(res => {
        console.log('response', { res });
        setCookie('current_profile', item._id);
        const selected_profile = profiles?.filter(itm => itm?._id === item?._id)[0];
        setUser({ ...selected_profile, media: selected_profile?.media[0] });
        navigate(`/user/profile?id=${res?.profileId}`);
      })
      .catch(err => {
        console.log(err);
      });
  };
  return (
    <div className="change-component">
      <div className="logo-holder">
        <img src={logo} alt="logo" />
      </div>
      <div className="login-img" style={{ backgroundImage: `url(${laptop})` }} />
      <div className="form-holder">
        <div className="text-holder">
          <h2>Choose Profile to link with QR</h2>
        </div>
        <div className="content-holder">
          {/* {user?.profiles?.map(itm => ( */}
          {profiles_loading ? (
            <Loading />
          ) : (
            profiles
              ?.filter(itm => !itm?.qr?.qr)
              ?.map(val => (
                <div
                  className="avatar-holder"
                  onClick={() => {
                    handleClickProfile(val);
                  }}>
                  <Avatar sm url={val?.media[0]?.profile_photo} />
                  <span className="name">{val?.name}</span>
                </div>
              ))
          )}

          {/* ))} */}
          <div className="btn-holder">
            <Button
              variant="primary"
              type="submit"
              onClick={() => {
                navigate('/user/choose-profile');
              }}>
              <span>
                <FontAwesomeIcon icon={faUserPlus} />
              </span>
              Change Profile
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LinkProfileToQR;
