import React, { useState } from 'react';
import Field from '../Field';
import './CreatePostForm.scss';
import Button from '../Button';
import userService from '../../services/userService';

function CreatePostForm({ values, setValues, errors, setErrors, loading, onSubmit = () => {} }) {
  const { categories_data } = userService.GetCategories();

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit();
  };
  const validateField = (name, value) => {
    // Implement field-specific validation logic here
    let error = '';

    if (name === 'caption' && !value) {
      error = 'Caption is required';
    }

    if (name === 'details' && !value) {
      error = 'Details is required';
    }

    if (name === 'category' && !value) {
      error = 'Category is required';
    }

    // Add more field-specific validation rules as needed

    return error;
  };
  const handleChange = ({ name, value }) => {
    setValues(prev => ({ ...prev, [name]: value }));
    const validationError = validateField(name, value);
    setErrors(prev => ({ ...prev, [name]: validationError })); // Set the error for the corresponding field
  };

  return (
    <div className="edit-form">
      <form onSubmit={handleSubmit}>
        <Field
          type="text"
          variant="form"
          name="caption"
          value={values.caption}
          error={errors?.caption}
          label=""
          placeholder="caption"
          onChange={({ name, value }) => {
            handleChange({ name, value });
          }}
        />
        <Field
          type="textarea"
          variant="form"
          name="details"
          rows="4"
          error={errors?.details}
          label=""
          value={values.details}
          placeholder="Details"
          onChange={({ name, value }) => {
            handleChange({ name, value });
          }}
        />
        <Field
          type="select"
          variant="form"
          name="category"
          error={errors?.category}
          label=""
          value={values.category?.value}
          placeholder="Post Category"
          options={categories_data}
          onChange={({ name, value }) => {
            handleChange({ name, value });
          }}
        />
        <div className="btn-holder">
          <Button type="submit" variant="primary" loading={loading}>
            Save
          </Button>
        </div>
      </form>
    </div>
  );
}

export default CreatePostForm;
