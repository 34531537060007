import { useEffect } from 'react';

function useClickOutside(ref, callback) {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  // Optionally, you can add touch event listeners for mobile devices
  useEffect(() => {
    document.addEventListener('touchstart', handleClick);
    return () => {
      document.removeEventListener('touchstart', handleClick);
    };
  }, []);

  return ref;
}

export default useClickOutside;
