import React, { useContext, useEffect, useState } from 'react';
// import Following from '../../components/Following';
// import TagsList from '../../components/TagsList';
// import Posts from '../../components/Posts';
import ProfileCover from '../../components/ProfileCover';
import Tabset from '../../components/Tabset';
import userService from '../../services/userService';
import Loading from '../../components/Loading';

function ProfileView({ showQr }) {
  const queryParams = new URLSearchParams(window.location.search);
  const userId = queryParams.get('id') ?? queryParams.get('profile');
  const postId = queryParams.get('post');
  // const saved = queryParams.get('saved');
  // const profileId = queryParams.get('profile');
  const [loading, setLoading] = useState(queryParams?.size > 0 ?? false);
  const [userData, setUserData] = useState(null);
  const [posts, setPosts] = useState([]);
  const [postsLoading, setPostsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [saved, setSaved] = useState(null);
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    pageSize: 10000,
  });
  const handleTabClick = tabIndex => {
    setActiveTab(tabIndex);
    if (tabIndex !== 3) {
      setSaved(false);
    }
    if (tabIndex === 3) {
      setSaved(true);
    }
  };
  useEffect(() => {
    if (queryParams.get('saved')) {
      setSaved(true);
    }
    (async () => {
      if (userId) {
        userService
          .getCurrentProfile(userId)
          .then(async data => {
            setUserData(data?.data);
            setLoading(false);

            const res = await userService.getPosts(
              data?.data?._id,
              postId,
              null,
              searchQuery.page,
              searchQuery.pageSize,
              true,
              saved,
            );
            if (res?.posts?.length) {
              setPosts(res?.posts);
              setPostsLoading(false);
            } else {
              setPostsLoading(false);
            }
          })
          .catch(error => {
            console.log('Error fetching user data:', error);
            setLoading(false);
            setPostsLoading(false);
          });
      }
      // else {
      //   const res = await userService.getPosts(profileId, postId);
      //   if (res?.posts?.length) {
      //     setPosts(res?.posts);
      //     setPostsLoading(false);
      //   } else {
      //     setPostsLoading(false);
      //   }
      //   setLoading(false);
      //   setPostsLoading(false);
      // }
    })();
    if (queryParams.get('saved')) {
      setSaved(true);
      handleTabClick(3);
    }
  }, [window?.location?.search]);
  useEffect(() => {
    if (userData) {
      (async () => {
        const res = await userService.getPosts(
          userData?._id,
          postId,
          null,
          searchQuery.page,
          searchQuery.pageSize,
          true,
          saved,
        );
        if (res?.posts?.length) {
          setPosts(res?.posts);
          setPostsLoading(false);
        } else {
          setPostsLoading(false);
        }
      })();
    }
  }, [activeTab, userData]);

  return (
    <section className="container">
      {loading ? (
        <Loading />
      ) : (
        <>
          <ProfileCover showQr={showQr} userData={userData} />
          <Tabset
            data={posts}
            loading={postsLoading}
            userData={userData}
            activeTab={activeTab}
            onClick={tabIndex => {
              handleTabClick(tabIndex);
            }}
          />
        </>
      )}
    </section>
  );
}

export default ProfileView;
