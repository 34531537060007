import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';
import Field from '../../components/Field';
import laptop from '../../images/login_page.jpg';
import './Login.scss';
import logo from '../../images/logo_ilegacy.png';
import Button from '../../components/Button';
import userService from '../../services/userService';
import Toast from '../../components/Toast';
import { getCookie, setCookie } from '../../helpers/common';

function Login() {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const sessionID = getCookie('itombs_sessionID');
    if (!sessionID) {
      setCookie('itombs_sessionID', uuidv4());
    }
  }, []);
  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    try {
      const res = await userService.login(name);
      if (res?.code) {
        Toast({
          type: 'success',
          message: 'A verification code is sent on email',
        });
        navigate('/user/verify-otp');
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      navigate('/user/verify-otp');

      Toast({ type: 'error', message: err?.message });
    }
  };
  return (
    <div className="login-component">
      {/* <Toast /> */}
      <div className="logo-holder">
        <img src={logo} alt="logo" />
      </div>
      <div className="login-img" style={{ backgroundImage: `url(${laptop})` }} />
      <div className="form-holder">
        <div className="text-holder">
          <h2>Email Address</h2>
          <p>Welcome Back, you have been missed</p>
        </div>
        <div>
          <form className="form" onSubmit={handleSubmit}>
            <Field
              label=""
              placeholder="Email Address"
              disabled={loading}
              value={name}
              onChange={({ name, value }) => {
                setName(value);
              }}
              // error={error && error.includes('Name')}
            />
            <div className="btn-holder">
              <Button variant="primary" type="submit" loading={loading}>
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
