/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import Avatar from '../Avatar';
import './SuggestedFollowers.scss';
import Button from '../Button';
import { getCookie } from '../../helpers/common';
import userService from '../../services/userService';
import Toast from '../Toast';
import Loading from '../Loading';
import UserContext from '../../context/userContext';

function SuggestedFollowers() {
  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    itemsPerPage: 10,
    profileId: getCookie('current_profile'),
  });
  const [followers, setFollowers] = useState([]);
  const { followers_data, followers_loading } = userService.GetSuggestedFollowers(searchQuery);
  const { showFollowers, setFollowedProfiles, setShowFollowers } = useContext(UserContext);
  useEffect(() => {
    const { data } = followers_data;
    if (followers_data?.data?.length) {
      setFollowers(data);
    } else {
      setFollowers([]);
    }
    return () => {
      setShowFollowers(false);
    };
  }, [followers_data?.data]);

  const handleFollow = async profileId => {
    try {
      await userService.followOrUnfollow({
        profile_id: getCookie('current_profile'),
        type: 'follow',
        following_profile_id: profileId,
      });
      const filtered_followers = followers?.map(itm => {
        const follower = itm;
        if (itm?._id === profileId) {
          follower.is_followed = true;
          setFollowedProfiles(prev => [...prev, follower]);
        }
        return follower;
      });

      setFollowers(filtered_followers);
    } catch (error) {
      Toast({ type: 'error', message: error?.message ?? 'Something went wrong' });
    }
  };
  const handleProfileClick = profileId => {
    navigate(`/user/profile?id=${profileId}`);
  };
  const handleClickOutside = () => {
    setShowFollowers(false);
    document.body.style.overflow = 'visible';
  };

  return (
    <div className={`suggested-followers ${showFollowers ? 'active' : ''}`}>
      <div className="icon" id="cross-icon" onClick={handleClickOutside}>
        <FontAwesomeIcon icon={faCircleXmark} />
      </div>
      <div className="heading">Suggestions for you</div>
      <div className="container">
        {followers_loading && <Loading />}
        {!followers_loading &&
          followers?.map(itm => (
            <div className="follower">
              <div className="name-holder">
                <div className="avatar-holder">
                  <Avatar
                    url={itm?.profile_photo}
                    md
                    onClick={() => {
                      handleProfileClick(itm._id);
                    }}
                  />
                </div>
                <div className="text-holder">
                  <p>{itm?.name}</p>
                </div>
              </div>
              <div className="btn-wrap">
                <Button
                  variant="primary"
                  disabled={itm?.is_followed}
                  sm
                  onClick={() => {
                    handleFollow(itm?._id);
                  }}>
                  {itm?.is_followed ? 'Following' : 'Follow'}
                </Button>
              </div>
            </div>
          ))}
        {!followers_loading && !followers?.length && (
          <div className="text-danger no-record">
            <p>No new suggestions</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default SuggestedFollowers;
