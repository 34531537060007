/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faFaceSmile, faBookmark, faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { faEllipsis, faPlus } from '@fortawesome/free-solid-svg-icons';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Avatar from '../Avatar';
import Button from '../Button';
import CommentModal from '../CommentModal';
import userService from '../../services/userService';
import { formatTimeAgo, getCookie, isUser } from '../../helpers/common';
import Toast from '../Toast';
import SharePost from '../SharePost';
import useClickOutside from '../../helpers/hooks/useOutsideClick';
import UserContext from '../../context/userContext';

function PostCard({ item, handlePostAction = () => {}, key }) {
  const [showPicker, setShowPicker] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenShare, setIsOpenShare] = useState(false);
  const [comment, setComment] = useState('');
  const emojiRef = useRef(null);
  const [liked, setLiked] = useState(false); // Track liked status
  const [favourite, setFavourite] = useState(item?.saved ?? false);
  const [commentLoading, setCommentLoading] = useState(false);
  const [totalComments, setTotalComments] = useState(0);
  const { hasPermission } = useContext(UserContext);

  const myRef = useRef(null);

  const handleClickOutside = () => {
    // Your logic to handle clicks outside the component
    setIsOpenShare(false);
  };
  useClickOutside(myRef, handleClickOutside);
  const handleEmojiSelect = emoji => {
    // setShowPicker(false);
    setComment(comment.concat(emoji.native));
    // onEmojiSelect(emoji.native);
  };

  const handleInput = e => {
    const { value } = e.target;
    setComment(value);
  };
  const handleLikeUnlike = async postId => {
    try {
      const profile_id = getCookie('current_profile');
      const res = await userService.likePost({ postId, profileId: profile_id });
      if (res?.liked) {
        setLiked(true);
        handlePostAction(postId, 'liked', key);
      } else {
        setLiked(false);
        handlePostAction(postId, 'unliked', key);
      }
    } catch (error) {
      console.log({ error });
    }
  };
  const handleBookmark = async post => {
    const { id } = post;
    const action = favourite ? 'unsave' : 'save';
    try {
      const profile_id = getCookie('current_profile');
      const res = await userService.savePost({ postId: id, profileId: profile_id, action });
      if (res?.saved) {
        setFavourite(true);
      } else {
        setFavourite(false);
      }
    } catch (error) {
      console.log({ error });
    }
  };
  const isVideoUrl = url => {
    const videoExtensions = ['.mp4', '.avi', '.mov', '.mkv', '.wmv', '.webm']; // Add more video extensions if needed
    const lowerCaseUrl = url.toLowerCase();
    return videoExtensions.some(ext => lowerCaseUrl.endsWith(ext));
  };

  const handleAddComment = async postId => {
    setCommentLoading(true);
    try {
      const payload = {
        message: comment,
        reply_to: null,
        profile_id: getCookie('current_profile'),
        post_id: postId,
      };
      await userService.addComment(payload);
      setComment('');
      setCommentLoading(false);
      setTotalComments(prev => prev + 1);
    } catch (error) {
      Toast({ type: 'error', message: error?.message });
      setCommentLoading(false);
    }
  };
  useEffect(() => {
    if (item?.total_comments) {
      setTotalComments(item?.total_comments);
    }
  }, []);
  return (
    <>
      <div className="post-card">
        <div className="user-info">
          <div className="avatar-holder">
            <Avatar url={item?.profile_url} />
            <h5>{item?.name}</h5>
          </div>
          <div className="icon">
            <FontAwesomeIcon icon={faEllipsis} />
          </div>
        </div>
        <div className="img-holder">
          {isVideoUrl(item?.img_url) ? (
            <video controls>
              <source src={item?.img_url} type="video/mp4" />{' '}
              {/* You can add more source types based on the video formats you support */}
              Your browser does not support the video tag.
            </video>
          ) : (
            <>
              <img src={item?.img_url} alt="post" />
              <span className="tags">{item?.category_name ?? null}</span>
            </>
          )}
        </div>
        <div className="info-holder">
          <div className="icons-holder">
            <div className="social-icons" ref={myRef}>
              <div
                className={`icon ${liked || item?.liked_myself ? 'active-heart' : ''}`}
                onClick={() => {
                  hasPermission(() => handleLikeUnlike(item?.id));
                }}>
                <FontAwesomeIcon icon={faHeart} />
              </div>
              <div
                className="icon"
                ref={myRef}
                onClick={() => {
                  setIsOpenShare(prev => !prev);
                }}>
                <FontAwesomeIcon icon={faPaperPlane} />
              </div>
              {isOpenShare && (
                <div className="share-box">
                  <SharePost
                    onShareWindowClose={() => {
                      setIsOpenShare(false);
                    }}
                    post={item}
                  />
                </div>
              )}
            </div>
            <div
              className={`icon ${favourite || item?.saved ? 'favourite' : ''}`}
              onClick={() => {
                hasPermission(() => handleBookmark(item));
              }}>
              <FontAwesomeIcon icon={faBookmark} />
            </div>
          </div>
          <div className="followers">
            <span>
              {item?.likes} Like
              {(() => {
                switch (item?.likes) {
                  case 1:
                    return null;
                  default:
                    return 's';
                }
              })()}
            </span>
            <span>{formatTimeAgo(item?.timestamp)}</span>
          </div>
          <div className="title">
            <p>{item?.title}</p>
          </div>
          {totalComments > 0 && (
            <div className="comment-count">
              <span
                onClick={() => {
                  setIsOpen(true);
                }}>
                View all {totalComments} comments
              </span>
              <Button
                variant="primary"
                onClick={() => {
                  setIsOpen(true);
                }}>
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          )}

          <div className="add-comment">
            <div className="input-holder">
              <span className="smiley">
                <div
                  ref={emojiRef}
                  className="ico-smile"
                  onClick={() => {
                    setShowPicker(!showPicker);
                  }}>
                  <FontAwesomeIcon icon={faFaceSmile} />
                </div>

                {/* {showPicker && <Picker onSelect={handleEmojiSelect} set="emojione" emojiSize={24} showPreview={false} />} */}
                {showPicker && (
                  <Picker
                    data={data}
                    onEmojiSelect={handleEmojiSelect}
                    onClickOutside={e => {
                      if (emojiRef.current && !emojiRef.current.contains(e.target)) {
                        setShowPicker(false);
                      }
                    }}
                  />
                )}
              </span>
              <input type="text" value={comment} placeholder="Add comment.." onChange={handleInput} />
              <Button
                variant="primary"
                disabled={!comment?.length || commentLoading || !isUser()}
                loading={commentLoading}
                onClick={() => {
                  hasPermission(() => handleAddComment(item.id));
                }}>
                Post
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <CommentModal isOpen={isOpen} setIsOpen={setIsOpen} post={item} setTotalComments={setTotalComments} />}
    </>
  );
}

export default PostCard;
