import React, { useState } from 'react';
import './UploadMedia.scss';
import { StaticImage } from 'gatsby-plugin-image';
import Toast from '../Toast';

function UploadMedia({ name, onChange = () => {} }) {
  console.log('rendered');
  const [file, setFile] = useState(null);
  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const isValidFile = val => {
    if (val.type.startsWith('video/mp4')) {
      return true;
    }
    if (val.type.startsWith('image/')) {
      return true;
    }
    return false;
  };
  const onUploadFile = val => {
    const reader = new FileReader();
    reader.onload = () => {};
    console.log({ val, s: val?.type });
    if (val) {
      console.log({ valtype: val?.type });
      reader.readAsDataURL(val);
      if (val.type.startsWith('video/mp4')) {
        onChange({ name, value: val });
      } else if (val.type.startsWith('image/')) {
        onChange({ name, value: val });
      } else {
        Toast({ type: 'error', message: 'Only mp4/images are allowed' });
        onChange({ name, value: null });

        return false;
      }
    }
    console.log({ file });
    // onChange({ name, value: val });
  };
  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    const data = e.dataTransfer.files[0];
    if (isValidFile(data)) {
      setFile(data);
    } else {
      setFile(null);
    }
    onUploadFile(data);
  };

  const handleInputChange = e => {
    const data = e.target.files[0];
    if (isValidFile(data)) {
      setFile(data);
    } else {
      setFile(null);
    }
    onUploadFile(data);
  };

  return (
    <div
      className="upload-media"
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}>
      {file ? (
        <>
          <div className="uploaded-file">
            {file.type.includes('image') ? (
              <img src={URL.createObjectURL(file)} alt="uploaded file" />
            ) : (
              <video src={URL.createObjectURL(file)} controls />
            )}
          </div>
          <div className="btn-holder">
            <label htmlFor="upload-input" className="change-file">
              {/* <Button variant="primary">Change File</Button> */}
              Change File
            </label>
            <input
              type="file"
              id="upload-input"
              className="upload-file"
              accept="image/*, video/*"
              onChange={handleInputChange}
            />
          </div>
        </>
      ) : (
        <>
          <label htmlFor="upload-input" className="upload-label">
            <span className="upload-text">
              Drag drop or browse Upload image
              <br /> or video
            </span>
            <div className="icon-holder">
              <StaticImage src="../../images/camera.svg" alt="camera" />
            </div>
          </label>
          <input
            type="file"
            id="upload-input"
            className="upload-file"
            accept="image/*, video/*"
            onChange={handleInputChange}
          />
        </>
      )}
    </div>
  );
}

export default UploadMedia;
