/* eslint-disable react/jsx-filename-extension */
import React, { useContext, useEffect } from 'react';
import { Router } from '@gatsbyjs/reach-router';
import PrivateRoute from '../components/PrivateRoute';
import Login from '../views/Login';
import Otp from '../views/Otp';
import EditProfile from '../views/EditProfile';
import CreatePost from '../views/CreatePost';
import HomeFeed from '../views/HomeFeed';
import ProfileView from '../views/ProfileView';
import ChangeProfile from '../components/ChangeProfile';
import LinkProfileToQR from '../components/LinkProfileToQR';
import userService from '../services/userService';
import { getCookie } from '../helpers/common';
import UserContext from '../context/userContext';

function User() {
  const { setUser, user } = useContext(UserContext);

  useEffect(() => {
    const profile_id = getCookie('current_profile');
    if (profile_id && !user) {
      userService
        .getCurrentProfile(profile_id)
        .then(async data => {
          setUser({ ...data?.data, media: data?.data?.media });
          // setLoading(false);
        })
        .catch(err => {
          console.log({ err });
        });
    }
  }, []);
  return (
    // <Layout>
    <Router>
      <PrivateRoute path="/user/verify-otp" component={Otp} hideLayout state={{ hideLayout: true }} />
      <PrivateRoute path="/user/edit-profile" component={EditProfile} state={{ hideLayout: true }} />
      <PrivateRoute path="/user/create-profile" component={EditProfile} create />
      <PrivateRoute path="/user/choose-profile" component={ChangeProfile} state={{ hideLayout: true }} />
      <PrivateRoute path="/user/link-profile-qr" component={LinkProfileToQR} state={{ hideLayout: true }} />
      <PrivateRoute path="/user/create-post" component={CreatePost} state={{ hideLayout: true }} />
      {/* <PrivateRoute
        path="https://ilegacybook.com/collections/all"
        component={Merchandise}
        state={{ hideLayout: true }}
      /> */}
      <PrivateRoute path="/user/feed" component={HomeFeed} state={{ hideLayout: true }} />
      <PrivateRoute path="/user/profile" component={ProfileView} showQr />
      <Login path="/user/login" hideLayout />
    </Router>
    // </Layout>
  );
}

export default User;
