/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import './Tabset.scss';
import PostView from '../../views/ProfileView/PostView';
import GuestBook from '../../views/ProfileView/GuestBook';
import SavedPosts from '../../views/ProfileView/SavedPosts';
import { getCookie } from '../../helpers/common';

function Tabset({ data, loading, userData, activeTab, onClick = () => {} }) {
  return (
    <div>
      <div className="tabset">
        <div
          className={`tab ${activeTab === 1 ? 'active' : ''}`}
          onClick={() => {
            onClick(1);
          }}>
          Posts
        </div>
        <div
          className={`tab ${activeTab === 2 ? 'active' : ''}`}
          onClick={() => {
            onClick(2);
          }}>
          Guest Book
        </div>
        {userData?._id === getCookie('current_profile') && (
          <div
            className={`tab ${activeTab === 3 ? 'active' : ''}`}
            onClick={() => {
              onClick(3);
            }}>
            Saved
          </div>
        )}
      </div>

      {/* Render content based on the selected tab */}
      {activeTab === 1 && <PostView posts={data} loading={loading} />}
      {activeTab === 2 && <GuestBook user={userData} />}
      {activeTab === 3 && <SavedPosts posts={data} loading={loading} />}
    </div>
  );
}

export default Tabset;
