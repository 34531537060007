import React, { useState } from 'react';
import { navigate } from 'gatsby';
// import { isLoggedIn } from '../utils/auth';

function PrivateRoute({ component: Component, location, hideLayout, ...rest }) {
  const [isLoggedIn] = useState(true);
  if (!isLoggedIn && location.pathname !== `/app/login`) {
    // If we’re not logged in, redirect to the home page.
    navigate(`/user/login`);
    return null;
  }

  return <Component hideLayout={hideLayout} {...rest} />;
}

export default PrivateRoute;
